
import "@hotwired/turbo-rails";
import "jquery"
import "parsleyjs/src/parsley.js";
import "parsleyjs/src/i18n/fr.js";
import "parsleyjs/src/i18n/es.js";
import "parsleyjs/src/i18n/it.js";
import "parsleyjs/src/i18n/de.js";
import "jquery-ui/dist/jquery-ui.js";
// import "jquery-smooth-scroll"
import "bootstrap/dist/js/bootstrap.bundle.js";
import "moment/src/moment.js";
import "moment/src/locale/de.js";
import "moment/src/locale/es.js";
import "moment/src/locale/fr.js";
import "moment/src/locale/it.js";
import "@vendor/jquery.rwdImageMaps.js";
import "@vendor/jquery.capsChecker.js";
import "@vendor/handlebars-v1.2.0";
import "./jquery.flexslider.js"
// import "bootstrap-datetimepicker"
// import "dataTables/jquery.dataTables"


import "./custom.js"
import "./events.js"
import "./tutors/custom.js"
import "./tableau/custom.js"

$(document).ready(function(){
    $('[data-toggle="tooltip"]').tooltip();

    $(function() {
      setTimeout(function(){
        $('.alert').slideUp(500);
      }, 5000);
    });

    $("select[name='filter_for_publisher_select']").on('change', function() {
      var url = $(this).val();
      if (url) { // require a URL
          window.location = url; // redirect
      }
      return false;
    });

    $("header.user-role-select-form .signup-steps input:submit[name!='commit']").on('click', function(event) {
      event.preventDefault();
      manageStepChange(this);
    });

    $('header.user-role-select-form .flat-box-steps-circle div').on('click', function() {
      manageStepCircles(this);
    });

    $("header.user-role-select-form .signup-steps input:submit[name='commit']").on('click', function(event) {
      event.preventDefault();
      $(this).closest('form').submit();
    });

    var userLang = navigator.language || navigator.userLanguage;
    userLang = userLang.substring(0, 2).toLowerCase();
    if (!['it', 'en', 'fr', 'de', 'es'].includes(userLang)) {
      userLang = 'en';
    }
    window.Parsley.setLocale(userLang);
    if($('#new_user').length != 0) {
      $('#new_user').parsley();
    }

    $('header.user-role-select-form input').on('change keyup', function() {
      manageInputChange(this);
    });

    $('.step-1-role1 input').on('change keyup', function() {
      manageDisableBtn(this, 1);
    });

    $('.step-2-role1 input').on('change keyup', function() {
      manageDisableBtn(this, 2);
    });

    $('.step-1-role1 select').on('change keyup', function() {
      manageDisableBtn(this, 1);
    });

    $('.step-2-role1 select').on('change keyup', function() {
      manageDisableBtn(this, 2);
    });

    $('.step-3-role1 input').on('change keyup', function() {
      manageDisableBtn(this, 3);
    });

    $('.step-1-role3 input').on('change keyup', function() {
      manageDisableBtn(this, 1);
    });

    $('.step-1-role1 select').on('change keyup', function() {
      $('.userbday-day').parsley().validate();
      $('.userbday-month').parsley().validate();
      $('.userbday-year').parsley().validate();
      manageDisableBtn(this, 1);
    });

    $('.step-2-role3 input').on('change keyup', function() {
      manageDisableBtn(this, 2);
    });

    $('.step-1-role3 select').on('change keyup', function() {
      $('.userbday-day').parsley().validate();
      $('.userbday-month').parsley().validate();
      $('.userbday-year').parsley().validate();
      manageDisableBtn(this, 1);
    });

    $('.step-2-role3 select').on('change keyup', function() {
      manageDisableBtn(this, 2);
    });

    $('.step-1-role2 input').on('change keyup', function() {
      manageDisableBtn(this, 1);
    });

    $('.step-1-role2 select').on('change keyup', function() {
      $('.userbday-day').parsley().validate();
      $('.userbday-month').parsley().validate();
      $('.userbday-year').parsley().validate();
      manageDisableBtn(this, 1);
    });

    $('.step-2-role2 input').on('change keyup', function() {
      manageDisableBtn(this, 2);
    });

    $('.step-2-role2 select').on('change keyup', function() {
      manageDisableBtn(this, 2);
    });

    $('.step-3-role2 input').on('change keyup', function() {
      manageDisableBtn(this, 3);
    });

    $('.step-3-role2 select').on('change keyup', function() {
      $('.parentbday-day').parsley().validate();
      $('.parentbday-month').parsley().validate();
      $('.parentbday-year').parsley().validate();
      manageDisableBtn(this, 3);
    });

    $('.step-4-role2 input').on('change keyup', function() {
      manageDisableBtn(this, 4);
    });

    $('.step-3-role3 input').on('change keyup', function() {
      manageDisableBtn(this, 3);
    });

    // ugly hack to bypass a chrome bug that ignores autocomplete="off" attribute
    $("#city").attr( "autocomplete", "none" );

    $('.ui-autocomplete').on('click', function(event) {
      var sel = $('#school_id');
      var city = $('#city').val();
      var nation = $('#nation').val();
      sel.find('option').remove();
      window.fillSchools(sel, city, nation);
    });

    $('#header.user-role-select-form select#nation').on('change', function(event) {
      $('#city').val('');
      $('#school_id').find('option').remove();
    });

    $('#header.user-role-select-form select#nation').on('change', function() {
      if ($(this).val() == "NP") {
        $('.school-inputs').hide();
        $('#city').prop('required',false);
        $('#city').attr('data-parsley-required', 'false');
        $('#school_id').prop('required',false);
        $('#school_id').attr('data-parsley-required', 'false');
        $('#city').val('');
        $('#school_id').empty();
        $('#nation').parsley().validate();
        manageDisableBtn(this, 1);
      } else {
        $('.school-inputs').show();
        $('#city').prop('required',true);
        $('#city').attr('data-parsley-required', 'true');
        $('#school_id').prop('required',true);
        $('#school_id').attr('data-parsley-required', 'true');
      }
    });

    // required to avoid double-click on submit button
    $(".btn-signup-flow-last").one('click', function (event) {
           $(this).prop('disabled', true);
     });
    $(".btn-signup-flow-last").dblclick(function(e){
      e.preventDefault();
    });


    $('#header.user-role-select-form select#nation').on('change', function() {
      if ($(this).val() == "NP") {
        $('.school-inputs').hide();
        $('#city').prop('required',false);
        $('#city').attr('data-parsley-required', 'false');
        $('#school_id').prop('required',false);
        $('#school_id').attr('data-parsley-required', 'false');
        $('#city').val('');
        $('#school_id').empty();
        $('#nation').parsley().validate();
        manageDisableBtn(this, 1);
      } else {
        $('.school-inputs').show();
        $('#city').prop('required',true);
        $('#city').attr('data-parsley-required', 'true');
        $('#school_id').prop('required',true);
        $('#school_id').attr('data-parsley-required', 'true');
      }
    });

    // Required to trigger validation when step 1 is
    // completely autofilled with oauth data
    $( "#user_name" ).trigger( "change" );

    // Is `n` a number or numeric string (eg "3")?
    function isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    };

    // Pad `n` with `z` (or `0` by default) to `width`.
    function zeroPad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    };

    window.Parsley
      .addValidator('checkOverAge', {
        requirementType: 'string',
        validateString: function(value, requirements) {
          var classId = requirements.split('-')[0];
          var age = requirements.split('-')[1];
          var day     = $('.' + classId + '-day').val(),
              month   = $('.' + classId + '-month').val(),
              year    = $('.' + classId + '-year').val();

          if (isNumeric(day) === false
            || isNumeric(month) === false
            || isNumeric(year) === false) {
            return false;
          };

          day     = zeroPad(day, 2);
          month   = zeroPad(month, 2);
          year    = zeroPad(year, 4);

          var inputDate = moment(year + '-' + month + '-' + day, 'YYYY-MM-DD');
          var birthDate = moment().subtract(age, 'years');

          if (inputDate.isValid() && inputDate <= birthDate) {
            return true;
          } else {
            return false;
          }
        }
        });

    window.Parsley
      .addValidator('checkUnderAge', {
        requirementType: 'string',
        validateString: function(value, requirements) {
          var classId = requirements.split('-')[0];
          var age = requirements.split('-')[1];
          var day     = $('.' + classId + '-day').val(),
              month   = $('.' + classId + '-month').val(),
              year    = $('.' + classId + '-year').val();

          if (isNumeric(day) === false
            || isNumeric(month) === false
            || isNumeric(year) === false) {
            return false;
          };

          day     = zeroPad(day, 2);
          month   = zeroPad(month, 2);
          year    = zeroPad(year, 4);

          var inputDate = moment(year + '-' + month + '-' + day, 'YYYY-MM-DD');
          var birthDate = moment().subtract(age, 'years');

          if (inputDate.isValid() && inputDate > birthDate) {
            return true;
          } else {
            return false;
          }
        }
        });
    
    window.Parsley.addValidator("rejectEmoji", {
      // requirementType: "string",
      validateString: function (value) {
        var regex = new RegExp(
          /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gu
        );
        return !regex.test(value);
      },
    });

    window.Parsley.addAsyncValidator(
      "emailUnique",
      function (xhr) {
        // Ritorna true anche se la richiesta non funziona per far
        // comunque andare avanti l'utente.
        // La validazione della mail c'è comunque alla fine del form.
        return xhr.status !== 200 || !xhr.responseJSON.found;
      },
      "/api/v5/users/email/check?email={value}"
    );

});

var getCurrentStep = window.getCurrentStep = function($this) {
  var classesParent = $($this).closest('.signup-steps').attr('class').split(' ');
  var curStep = classesParent[2].split('-')[1];
  return curStep;
};

var manageDisableBtn = window.manageDisableBtn = function($this, step) {
  var btn = $($this).closest('.signup-steps').find("input[type='submit']");
  var filled = $('#new_user').parsley().isValid({group: 'step_' + step});
  if (filled === true) {
    $(btn).prop('disabled', false);
  } else {
    $(btn).prop('disabled', true);
  }
};

var onPrivaciesChange = window.onPrivaciesChange = function($this, step) {
  if ($($this).is(':checked')) {
    $($this).attr('value', '1');
    $($this).attr('checked', true);
  } else {
    $($this).attr('value', '');
    $($this).attr('checked', null);
  }
  manageDisableBtn($this, step);
};

var dateSeparator = '/';

var correctCaptcha = window.correctCaptcha = function(response) {
  $('#recaptcha_status').val('1');
  var classesParent = $(".g-recaptcha").closest('.signup-steps').attr('class').split(' ');
  var curStep = classesParent[2].split('-')[1];
  manageDisableBtn(".g-recaptcha", curStep);
};

var manageStepCircles = window.manageStepCircles = function($this) {
  var currentIndex = $($this).siblings('.active').attr('class').split(' ')[0].split('-')[1];
  var selected = $($this).attr('class').split(' ')[0];
  var selectedIndex = selected.split('-')[1];
  if (selectedIndex*1 < currentIndex*1) {
    $('.flat-box.signup-steps').hide();
    $('.flat-box-header.signup-steps').hide();
    $('.flat-box-steps-circle').hide();
    $('.flat-box.signup-steps.'+ selected).show();
    $('.flat-box-header.signup-steps.'+ selected).show();
    $('.flat-box-steps-circle.'+selected).show();
    $("html, body").animate({ scrollTop: 0 }, "slow");
  }
};

var manageInputChange = window.manageInputChange = function($this) {
  var step = getCurrentStep($this);
  if ($($this).is(':checkbox')) {
    if ($($this).attr('id') == 'checkbox-form-tos_agreement') {
      $('.privacies input:checkbox').prop('checked', $($this).is(':checked'));
      $('.privacies input:checkbox').each(function() {
        onPrivaciesChange(this);
      });
    } else {
      $('#checkbox-form-tos_agreement').prop('checked', false);
      $('#checkbox-form-tos_agreement').attr('value', '');
    }
    onPrivaciesChange($this, step);
  }
};

var manageStepChange = function($this) {
  var classesParent = $($this).closest('.signup-steps').attr('class').split(' ');
  var curStep = classesParent[2].split('-')[1];
  var curFlow = classesParent[2].split('-')[2]
  var nextStep

  // Modification requested by DeA, to skip school selection if already provided in oauth data
  if ((curStep == 1) && ($('#school_id').val() != '') && ($('#school_id').val() != null)) {
    nextStep = 3;
    manageDisableBtn($('#nation'), 2); // if we skip step 2, its "next" button never gets enabled
  } else {
    nextStep = curStep*1+1;
  }

  $('.signup-steps').hide();
  if ($('.step-'+ nextStep + '-' + curFlow).hasClass('skip-step')) {
    $('#nation').prop('required',false);
    $('#nation').attr('data-parsley-required', 'false');
    $('#city').prop('required',false);
    $('#city').attr('data-parsley-required', 'false');
    $('#school_id').prop('required',false);
    $('#school_id').attr('data-parsley-required', 'false');
    nextStep = nextStep +1;
  }
  $('.step-'+ nextStep + '-' + curFlow).show();
  $("html, body").animate({ scrollTop: 0 }, "slow");
};

var gobackSignupFlow = window.gobackSignupFlow = function($this) {
  // event.preventDefault();
  var classesParent = $($this).closest('.signup-steps').attr('class').split(' ');
  var curStep = classesParent[2].split('-')[1];
  var curFlow = classesParent[2].split('-')[2]
  var nextStep = curStep*1-1;
  $('.signup-steps').hide();
  if ($('.step-'+ nextStep + '-' + curFlow).hasClass('skip-step')) {
    nextStep = nextStep - 1;
  }
  $('.step-'+ nextStep + '-' + curFlow).show();
  $("html, body").animate({ scrollTop: 0 }, "slow");
};

window.fillSchools = function fillSchools(obj, city, nation) {
  if (nation === undefined) {nation = 'NP'};
  obj.find('option').remove();
  obj.append("<option value=''> </option>");
  var sanitizedCity = document.getElementById("city").getAttribute('data-city-id');
  var sanitizedNation = nation.trim();
  if (sanitizedCity && sanitizedCity !== ""){
    var composedUrl = "/school_data/schools_list_by_city_id?equal=true&city_id=" + sanitizedCity;
    if (sanitizedNation !== 'NP') {
      composedUrl = composedUrl + '&nation=' + sanitizedNation;
    }
    $.ajax({url: composedUrl,
      dataType: "json",
      success: function(result){
        $.each(result, function(key, value)
        {
          obj.append('<option value=' + value.school_code + '>' + value.name + '</option>');
        });
    }});
  }
}
