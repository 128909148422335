import "../src/shared/wrap_ready.js";
import Cookie from "../src/shared/cookie";

window.Digibook = {};

window.addEventListener("DOMContentLoaded", () => {
  window.Digibook.Cookie = new Cookie();
});

import "../../whitelabels/digibook/assets/javascripts/application.js";
